@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.tooltipText {
  //positioning
  width: 100%;

  //font
  @include font('caption_m');
  line-height: 22px;

  //color
  color: $color-gray-700;

}