@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.content {
  position: relative;
  width: 100%;
  margin: 0 auto;
  word-break: keep-all;

  &.shadowStatusType {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.15);
  }

  &.LEFT {
    justify-content: flex-start;
  }

  &.CENTER {
    justify-content: center;
  }

  &.RIGHT {
    justify-content: flex-end;
  }

  &.flex .button {
    width: 100%;
  }
}
