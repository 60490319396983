@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
@mixin visible {
  .overlay {
    opacity: 1;
  }
  .editor-wrapper {
    opacity: 1;
  }
}

.container {
  position: absolute;
  left: 0;
  top: 0;

  display: flex;
  width: 100%;
  height: 100%;

  &.toggle-enabled {
    &.active-always {
      @include visible;
    }

    &.PC:hover,
    &.TABLET:hover {
      @include visible;
    }

    &.MOBILE.mobile-toggle-active {
      @include visible;
    }
  }

  .overlay {
    opacity: 0;
    transition: opacity 0.3s ease;
    position: absolute;
    left: 0;
    top: 0;

    width: 100%;
    height: 100%;
  }

  .editor-wrapper {
    display: flex;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    padding: 30px;
    width: 100%;

    &.TOP {
      align-items: start;
    }
    &.CENTER {
      align-items: center;
    }
    &.BOTTOM {
      align-items: end;
    }
  }
}
