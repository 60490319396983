@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
@mixin image-effect {
  &.round {
    border-radius: 100% !important;
    overflow: hidden;
  }

  &.mono {
    filter: grayscale(100%);
  }

  &.shadow {
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1), 0 4px 6px rgba(0, 0, 0, 0.05);
  }
}

.wrapper {
  display: flex;

  .image-size-area {
    display: flex;
    position: relative;
    width: 100%;
    overflow: hidden;

    .imageSizeWrap {
      width: 100%;
      height: 100%;
    }

    &.shadow {
      box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1), 0 4px 6px rgba(0, 0, 0, 0.05);
    }

    &.cursor {
      cursor: pointer;

      &:hover .img {
        transform: scale(1.1);
      }
    }

    &.LEFT {
      justify-content: left;

      .image-size-area {
        text-align: left;
      }
    }

    &.CENTER {
      justify-content: center;

      .image-size-area {
        text-align: center;
      }
    }

    &.RIGHT {
      justify-content: right;

      .image-size-area {
        text-align: right;
      }
    }

    &.empty {
      height: 318px;
    }

    .img {
      @include image-effect;
      width: 100%;
      height: auto;
      transition: 0.4s transform;
    }
  }
}
