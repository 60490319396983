@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.article-image {
  display: flex;
  background-color: $color-gray-50;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  &.empty {
    border: 1px solid $color-gray-400;
  }
}
