@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.recruit-header-setting {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px 24px;
  margin-bottom: 24px;
  padding-bottom: 14px;
  border-bottom: 1px solid $color-gray-400;

  .total-count {
    @include font('b2');
    color: $color-gray-800;

    .total-count-number {
      @include font('b2', true);
    }
  }

  .sort-dropdown {
    width: 160px;
  }
}
