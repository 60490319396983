@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.container {
  margin-bottom: 32px;

  .title-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    .title {
      font-size: 16px;
      font-weight: 700;
      line-height: 32px;
      color: $color-gray-800;
    }
  }
}
