@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
@import 'ui/styles/colors';
@import 'ui/styles/fonts';
@import 'ui/styles/base.scss';

.wrapper {
  position: relative;
  user-select: none;
  display: flex;

  .search-input {
    width: 100%;
  }

  @include font(b2, $no-line-height: true);

  cursor: pointer;
}

.tooltip-wrapper {
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 404;
  position: fixed;
  max-height: 100vh;
  background-color: $color-white;
  height: 100vh;
  transform: translate(0, 100%);
  transition: transform 0.3s;

  &.open {
    transform: translate(0, 0);
  }

  .header {
    padding: 16px 24px;
    @include font('h4');
    background-color: $color-white;
    border-radius: 12px 12px 0 0;
    text-align: center;
    border: 1px solid $color-gray-400;

    h4 {
      display: inline-block;
    }

    button {
      float: right;

      &:before {
        content: '';
        clear: both;
        display: block;
      }
    }
  }

  .options {
    z-index: 404;
    position: fixed;
    width: 100%;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15), 0 4px 6px rgba(0, 0, 0, 0.05);
    height: calc(100vh - 68px);
    padding: 24px;
    border: 1px solid $color-gray-400;

    .search-input {
      width: 100%;
      height: 48px;
    }
  }
}
