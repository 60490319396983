@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.container {
  border: 1px solid $color-gray-400;
  border-radius: 12px;
  display: flex;
  flex-direction: column;

  &.--active {
    border: 1px solid $color-gray-800;
  }
  &:hover {
    border: 1px solid $color-gray-800;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: $space-100;
    cursor: pointer;
  }

  .item-title {
    padding: 24px 32px;
  }

  .item-body {
    padding: 24px 32px;
  }

  .tab-list {
    display: flex;
    gap: 6px;
    flex-wrap: wrap;

    .tab-item {
      background-color: $color-gray-80;
      color: $color-gray-700;
      border-radius: 30px;
      padding: 4px 10px;

      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }

  .title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .editor {
      width: calc(100% - 38px);
      flex-shrink: 1;
    }

    .open-toggler {
      flex-shrink: 0;
    }
  }
}

.div-hori {
  margin: auto;
  margin: 0px 32px;
  width: calc(100% - 64px);
  height: 1px;
  background-color: $color-gray-400;
}
