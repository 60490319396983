@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.tooltipTitle {
  //positioning
  width: 100%;

  //font
  @include font('b3', true);
  line-height: 18px;

  //color
  color: $color-gray-800;
}
