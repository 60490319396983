@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.content {
  display: flex;

  .youtube-wrapper {
    overflow: hidden;

    .thumbnail-wrapper {
      position: relative;

      .youtube-logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        object-fit: cover;
        cursor: pointer;
      }

      .thumbnail-img {
        object-fit: cover;
      }
    }
  }
}
