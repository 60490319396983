@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.content {
  display: flex;

  &.LEFT {
    justify-content: flex-start;
  }

  &.CENTER {
    justify-content: center;
  }

  &.RIGHT {
    justify-content: flex-end;
  }

  &.flex .button {
    width: 100%;
  }

  .button {
    position: relative;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    cursor: default;

    &.cursor {
      cursor: pointer;
    }

    &.LARGE {
      padding: 14px 35px;
    }

    &.MEDIUM {
      padding: 10px 16px;
    }

    &.SMALL {
      padding: 6px 16px;
    }

    &.max_width {
      max-width: 100%;
    }
  }
}
