$radius-0: 0px;
$radius-25: 2px;
$radius-50: 4px;
$radius-75: 6px;
$radius-100: 8px;
$radius-150: 12px;
$radius-200: 16px;
$radius-300: 24px;
$radius-400: 32px;

$space-0: 8px;
$space-50: 4px;
$space-75: 6px;
$space-100: 8px;
$space-150: 12px;
$space-200: 16px;
$space-250: 20px;
$space-300: 24px;
$space-400: 32px;
$space-500: 40px;

$size-0: 0px;
$size-12: 1px;
$size-25: 2px;
$size-50: 4px;
$size-75: 6px;
$size-100: 8px;
$size-125: 10px;
$size-150: 12px;
$size-200: 16px;
$size-225: 18px;
$size-250: 20px;
$size-300: 24px;
$size-350: 28px;
$size-375: 30px;
$size-400: 32px;
$size-450: 36px;
$size-500: 40px;
