@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.div {
  height: 1px;
}
