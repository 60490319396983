@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
@import "./TooltipWrapper.mixin.module.scss";

/** 툴팁박스*/
.tooltipBox {
  //positioning
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  //padding, margin
  padding: 14px;

  //border styling
  border: 1px solid $color-gray-300;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12);

  //color
  background: $color-white;
}


/** 툴팁박스에 달려있는 꼬리*/
.tooltipBox {
  &:before, &:after {
    content:"";
    position:absolute;
  }

  &.top-left {
    @include tooltipTailPosition("top-left")
  }
  &.top {
    @include tooltipTailPosition("top")
  }
  &.top-right {
    @include tooltipTailPosition("top-right")
  }
  &.right-top {
    @include tooltipTailPosition("right-top")
  }
  &.right {
    @include tooltipTailPosition("right")
  }
  &.right-bottom {
    @include tooltipTailPosition("right-bottom")
  }
  &.bottom-right {
    @include tooltipTailPosition("bottom-right")
  }
  &.bottom {
    @include tooltipTailPosition("bottom")
  }
  &.bottom-left {
    @include tooltipTailPosition("bottom-left")
  }
  &.left-bottom {
    @include tooltipTailPosition("left-bottom")
  }
  &.left {
    @include tooltipTailPosition("left")
  }
  &.left-top {
    @include tooltipTailPosition("left-top")
  }
}


