@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.content {
  display: flex;
  flex-direction: column;
  padding: 40px 0 56px 0;

  .search-input {
    align-self: flex-end;
    width: 316px;
    margin-bottom: 8px;
  }

  .articles-container {
    margin-bottom: 56px;

    .pagination {
      margin-top: 56px;
    }
  }

  .no-article-result {
    display: flex;
    flex-direction: column;
    align-items: center;

    .article-register-button {
      width: 297px;
      margin-top: 8px;
    }
  }
}
