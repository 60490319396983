@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
@import "../../../../node_modules/ui/styles/fonts";
@import "../../../../node_modules/ui/styles/colors";

.container {
  @include font(b3);

  width: max-content;
  height: 64px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: bottom 0.4s cubic-bezier(0.33, 1, 0.68, 1), opacity 0.4s cubic-bezier(0.33, 1, 0.68, 1);
  box-shadow: 0 10px 15px rgb(0 0 0 / 10%), 0 4px 6px rgb(0 0 0 / 5%);
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  color: white;
  padding: 0 24px;
  background: $color-gray-800;
  pointer-events: none;
  z-index: 1001;

  &.snackbar {
    min-width: 640px;
  }

  &.visible {
    opacity: 1;
    pointer-events: unset;
  }

  &.positive {
    .icon {
      fill: $color-green-600;
    }
  }

  &.warning {
    .icon {
      fill: $color-yellow-600;
    }
  }

  &.failed {
    .icon {
      fill: $color-red-600;
    }
  }

  > .left {
    display: flex;
    align-items: center;
    gap: 8px;

    & + .button-wrap:not(:empty) {
      margin-left: 24px;
      display: flex;
      gap: 8px;

      > button {
        min-width: 96px;

        &.close-button {
          min-width: 77px;
        }
      }
    }
  }
}
