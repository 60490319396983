@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 900;

  .loading {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    background: white;
    text-align: center;
    overflow: hidden;
  }
}
