@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
@import 'ui/styles/colors';
@import 'ui/styles/fonts';

.recruit-kanban {
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  grid-gap: 12px;
  margin: 24px 0 0 0;
  padding: 0;
  z-index: 1;

  .recruit-item-wrapper {
    width: 100%;
    height: 240px;
    padding: 24px;
    list-style: none;
    background-color: white;
    border: 1px solid $color-gray-300;
    border-radius: 8px;
    list-style: none;
    cursor: pointer;
    transition: 0.2s box-shadow ease-in-out, 0.2s border ease-in-out;

    &:hover {
      z-index: 1;
      border: 1px solid $color-gray-800;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06), 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
    }

    .recruit-item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: 100%;

      .item-header {
        .filter-list {
          display: flex;
          align-items: center;
          list-style: none;
          margin: 0 0 6px 0;
          padding: 0;

          .filter-item {
            display: flex;
            align-items: center;
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            color: $color-gray-800;
          }
        }

        .recruit-title {
          font-size: 18px;
          font-weight: 700;
          line-height: 28px;
          color: $color-gray-900;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
        }
      }

      .item-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;

        .tag-and-date {
          .tag {
            position: relative;
            margin-bottom: 2px;
            @include font('b2', true);
            color: $color-gray-600;

            .tag-tooltip {
              position: absolute;
              bottom: 100%;
              left: 0;
              display: none;
              width: max(100%, calc(295px - 2 * 24px));

              .tag-tooltip-text {
                text-align: center;
              }
            }

            &:hover {
              .tag-tooltip {
                display: block;
              }
            }
          }

          .date {
            @include font('b3');
            color: $color-gray-600;
          }
        }

        .dday {
          flex-shrink: 0;
          font-size: 18px;
          font-weight: 700;
          line-height: 28px;
          color: $color-gray-800;
        }
      }
    }
  }
}
