@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.side-setting-accordion {
  .accordion-title-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
    background-color: $color-white;
    border-bottom: 1px solid $color-gray-400;
    z-index: 1;
    cursor: pointer;

    .title-area {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    .title {
      font-size: 16px;
      font-weight: 700;
      line-height: 32px;
      color: $color-gray-800;
    }

    .title-input {
      width: 200px;
    }

    .right-icon {
      transform: rotate(180deg);
      transition: transform 0.2s ease-in-out;

      &.open {
        transform: rotate(0deg);
      }
    }
  }

  .accordion-option-section {
    display: flex;
    flex-wrap: wrap;
    gap: 12px 8px;
    max-height: 0;
    margin-block: 16px;
    transition: max-height 0.3s ease-in-out;
    overflow: hidden;

    .option-item {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 36px;
      padding: 6px;
      background-color: $color-white;
      border: 1px solid $color-gray-400;
      border-radius: 8px;
      overflow: hidden;
      cursor: pointer;

      &.selected {
        border: 1px solid $color-gray-800;

        .option-label {
          font-weight: 700;
        }
      }

      .option-label {
        @include font('b3');
        font-weight: 400;
        color: $color-gray-800;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    &.open {
      max-height: 2000px;
    }
  }
}
