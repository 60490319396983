@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.imageSizeWrap {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;

  &.cursor {
    cursor: pointer;
  }

  &.RATIO {
    max-width: 1760px;
  }

  .media-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;

    .media {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &.correctLargeMobile {
      .media {
        width: 100%;
        min-height: 800px;
      }
    }
  }

  .overlay {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
  }
}
