@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
@import './ViewBoard.module.scss';

$ARTICLE_PADDING: 16px;
$ARTICLE_LIST_INNER_HEIGHT_PC: 230px;
$ARTICLE_LIST_INNER_HEIGHT_MO: 126px;

$ARTICLE_LIST_IMAGE_GAP_PC: 80px;
$ARTICLE_LIST_IMAGE_GAP_TABLET: 40px;
$ARTICLE_LIST_IMAGE_GAP_MO: 24px;

$ARTICLE_KANBAN_HEIGHT_PC: 496px;
$ARTICLE_KANBAN_HEIGHT_TABLET: 482px;

$ARTICLE_KANBAN_IMAGE_GAP: 24px;

@mixin board-inner-list {
  display: flex;
  flex-direction: column;

  .article {
    display: flex;
    align-items: center;
    width: 100%;
    height: calc($ARTICLE_LIST_INNER_HEIGHT_PC + 2 * $ARTICLE_PADDING);
    padding: $ARTICLE_PADDING;
    gap: $ARTICLE_LIST_IMAGE_GAP_PC;

    &:hover {
      cursor: pointer;
      border-radius: 12px;
      background-color: $color-gray-80;
    }

    .article-contents-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      overflow: hidden;

      .article-image {
        flex-shrink: 0;
      }

      .article-contents {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
  }

  .divider:last-child {
    display: none;
  }

  &.TABLET {
    .article {
      gap: $ARTICLE_LIST_IMAGE_GAP_TABLET;
    }
  }

  &.MOBILE {
    .article {
      height: calc($ARTICLE_LIST_INNER_HEIGHT_MO + 2 * $ARTICLE_PADDING);
      padding: $ARTICLE_PADDING 16px $ARTICLE_PADDING 0;
      gap: $ARTICLE_LIST_IMAGE_GAP_MO;

      &:hover {
        background-color: transparent;
      }

      .article-contents-container {
        .article-contents {
          gap: 4px;
          margin-bottom: 8px;
        }
      }
    }
  }
}

@mixin board-inner-kanban {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;

  .article {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    padding: $ARTICLE_PADDING;
    overflow: hidden;

    &:hover {
      cursor: pointer;
      border-radius: 12px;
      background-color: $color-gray-80;
    }

    .article-contents-container {
      width: 100%;
      overflow: hidden;

      .article-contents {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .article-title {
          min-height: 72px;
        }
      }
    }
  }

  &.TABLET {
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
  }

  &.MOBILE {
    grid-template-columns: repeat(1, 1fr);
    gap: 0;

    .article {
      height: auto;
      padding: $ARTICLE_PADDING 0;

      &:hover {
        background-color: transparent;
      }

      .article-contents-container {
        .article-contents {
          gap: 4px;

          .article-title {
            min-height: 0;
          }
        }
      }
    }
  }
}

.list-container {
  &.LIST {
    @include board-inner-list;
  }

  &.KANBAN {
    @include board-inner-kanban;
  }
}

// parts
.article-title {
  @include font('h5');
  color: $color-gray-900;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;

  &.MOBILE {
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
  }
}

.article-description {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: $color-gray-900;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &.MOBILE {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
  }
}

.post-info {
  display: flex;
  align-items: center;
  margin-top: 24px;

  &.MOBILE {
    margin-top: 0;
    align-items: flex-start;
    flex-direction: column;

    &.KANBAN {
      flex-direction: row;
      align-items: center;
    }
  }

  .start-time, .view-count{
    display: flex;
    align-items: center;
    gap: 4px;

    color: $color-gray-600;
  }
}

