@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
@import 'ui/styles/fonts';

.tags-wrapper {
  .tab {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    height: fit-content;
  }

  &.MOBILE {
    .tab {
      position: absolute;
      height: 42px;
      flex-wrap: nowrap;

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }

      &::-webkit-scrollbar-track {
        background: $color-white;
      }

      & > button {
        scroll-snap-align: start;
        flex-shrink: 0;
      }
    }
  }
}

.filter-list {
  display: flex;
  gap: 12px;
  margin-top: 32px;
  align-items: center;

  .keyword-filter {
    width: 100%;

    .filter {
      width: 100%;
    }
  }

  &.PC {
    .keyword-filter {
      width: 480px;
      min-width: 480px;
    }

    .filter {
      width: 160px;
      min-width: 160px;
    }

    &.full {
      .keyword-filter {
        width: 320px;
        min-width: 320px;
      }

      .filter {
        width: 160px;
        min-width: 160px;
      }
    }
  }

  &.TABLET {
    flex-direction: column;
    align-items: normal;
  }

  &.MOBILE {
    flex-direction: column;
    align-items: normal;
    margin-top: calc(32px + 42px);
  }
}

.reset {
  min-width: fit-content;
  width: fit-content;

  .MOBILE {
    margin-top: 24px;
  }
}
