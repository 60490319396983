@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.background-wrapper {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
  display: flex;
  justify-content: center;
  z-index: -1;

  .background {
    width: 100%;

    &.RATIO {
      max-width: 1760px;
    }
  }
}
