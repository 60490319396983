@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
@import '../../../../node_modules/ui/styles/base';

.alert {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  width: 600px;
  padding: 40px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 10px;
  margin-top: 32px;
}

.button {
  &-confirm,
  &-cancel {
    min-width: 95px;
    padding: 10px 0;
  }

  &-close {
    align-self: flex-end;
    fill: $color-gray-800;
    cursor: pointer;
  }
}

.alert {
  &-title {
    margin-bottom: 8px;
    color: $color-gray-700;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
  }

  &-description {
    margin-top: 8px;
    color: $color-gray-900;
    @include font('b1');
  }
}

.icon {
  &-error {
    fill: $color-error;
  }
}
