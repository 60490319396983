@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
@import 'ui/styles/colors';
@import 'ui/styles/fonts';

.container {
  display: flex;
  gap: 64px;
  max-width: 1216px;
  width: 100%;
  overflow: auto;

  .column {
    min-width: 0;

    &.content {
      flex: 1;
    }
  }
}
