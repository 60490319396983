@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.header {
  display: flex;
  justify-content: space-between;

  .tab-list {
    position: relative;

    &.--large &:not(.--mobile) {
      padding-top: 50px;
    }
  }
}

.searchbar {
  width: 320px;

  &.--mono {
    position: inherit;
    float: right;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .add-accordion-wrapper {
    display: flex;
    justify-content: center;

    .add-accordion {
      width: 132px;
      white-space: nowrap;
    }
  }
}

.no-result-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 72px;

  .no-result-btn {
    margin-top: 32px;
    margin-bottom: 28px;
  }
}

.--mobile {
  flex-direction: column;
  gap: 16px;

  .searchbar {
    width: 100%;
  }

  .tab {
    position: relative;
    overflow: visible;
  }

  .--large {
    padding-top: 0px;
  }
}
