@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
@import 'ui/styles/colors';
@import 'ui/styles/fonts';

.recruit-list {
  margin-top: 24px;

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
    }
  }

  .list-item {
    display: flex;
    border-bottom: 1px solid $color-gray-400;
    padding: 40px;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    width: 100%;

    &:not(:first-child) {
      margin-top: 8px;
    }

    &:hover {
      background-color: $color-gray-50;
    }

    .left {
      overflow: hidden;
      width: 100%;
    }

    .title-wrapper {
      display: flex;
      align-items: center;
      flex-shrink: 1;
      width: 100%;
      overflow: hidden;

      .fixed-pin {
        flex-shrink: 0;
      }

      .title {
        @include font('subtitle', true);
        margin: 0 12px;
        color: $color-gray-900;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        .submission-status-tag {
          position: relative;
          margin-right: 8px;
          top: -2px;
        }
      }
    }

    .condition-date {
      list-style: none;
      margin: 9px 0 0;
      padding: 0;
      display: flex;
      gap: 25px;

      .dday {
        position: relative;
        @include font('b2');
        color: $color-black;
        font-weight: 700;
        white-space: nowrap;
      }

      .date {
        position: relative;
        @include font('b2');
        color: $color-gray-700;
        display: flex;

        &:not(:first-child) {
          &:before {
            content: '';
            height: 12px;
            width: 1px;
            background-color: $color-gray-300;
            position: absolute;
            top: 5px;
            left: -13px;
          }
        }
      }
    }

    .filtered-list {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 48px;

      .filtered-item {
        width: 104px;
        color: $color-gray-700;

        p {
          @include font('b2');
          text-align: center;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }

  &.TABLET {
    .list-item {
      flex-direction: column;
      align-items: flex-start;

      .condition-date {
        margin: 0;

        .date {
          flex-direction: row;
          flex-wrap: wrap;
        }
      }

      .filtered-list {
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 12px;
        margin: 4px 0;

        .filtered-item {
          display: contents;

          p {
            width: fit-content;
          }

          &:not(:first-child) {
            &:before {
              content: '';
              border-radius: 100%;
              border: 1px solid $color-gray-300;
              width: 1px;
              height: 14px;
            }
          }
        }
      }
    }
  }

  &.MOBILE {
    .list-item {
      flex-direction: column;
      align-items: flex-start;
      padding: 40px 0;

      &:hover {
        background-color: transparent;
      }

      .top {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      .condition-date {
        display: inline;

        > * {
          display: inline;
        }

        .date {
          & > p {
            display: inline;
          }

          &:not(:first-child) {
            margin-left: 24px;
          }
        }
      }

      .title {
        @include font('subtitle', true);
        display: -webkit-box;
        max-height: 56px;
        margin: 8px 0 0 0;
        color: $color-gray-900;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .filtered-list {
        margin-top: 8px;
        gap: 10px;
        justify-content: start;
        flex-wrap: wrap;

        .filtered-item {
          display: contents;

          &:not(:first-child) {
            &:before {
              content: '';
              border-radius: 100%;
              border: 1px solid $color-gray-300;
              width: 1px;
              height: 14px;
            }
          }
        }
      }
    }
  }
}
