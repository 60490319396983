@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.map-wrap {
  width: 100%;
  margin: -1.5px -1.5px;
  display: flex;

  &.LEFT {
    justify-content: start;
  }

  &.CENTER {
    justify-content: center;
  }

  &.RIGHT {
    justify-content: end;
  }

  .map {
    width: 100%;
    aspect-ratio: 16/9;
    max-height: 430px;

    &.mobile {
      aspect-ratio: 4/3;
      min-height: 300px;
    }
  }

  .overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background: var(--pin-color, #327df5);
    padding: 10px 8px 10px 15px;
    border-radius: 4px;
    position: relative;
    bottom: 80px;
    cursor: pointer;
    z-index: 1;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);

    &-text {
      @include font('b2', true);
      font-size: 14px;
    }

    &::after {
      display: inline-block;
      content: '';
      background: url('/img/icon/chevron_right.svg') no-repeat;
      width: 20px;
      height: 20px;
      color: white;
      margin-left: 6px;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: -8px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid var(--pin-color, #327df5);
    }
  }
}

.description-wrap {
  margin-top: 16px;
  text-align: left;

  .pin-name {
    font-size: 24px;
    font-weight: 700;
    line-height: 34px;
    color: $color-gray-800;
  }

  .address-wrap {
    .basic-address,
    .address-divider,
    .detail-address {
      margin-top: 4px;
      font-size: 14px;
      line-height: 22px;
      color: $color-gray-600;
    }

    .address-divider {
      padding-right: 4px;
    }

    button {
      background: none;
      vertical-align: middle;
      display: inline-flex;
      align-items: center;

      .copy-icon {
        margin-left: 4px;
        cursor: pointer;
      }
    }
  }

  .phone-number {
    display: flex;
    align-items: center;
    gap: 4px;

    @include font('b4');
    margin-top: 4px;
    font-size: 12px;
    color: $color-gray-600;
  }
}
