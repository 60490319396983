//꼬리가 향하는 방향
@mixin tailDirection($direction) {
  @if $direction == "up" {
    //위쪽으로 뾰족
    &:before {
      bottom:100%;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 14px solid $color-gray-300;
    }
    &:after {
      bottom:100%;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 12px solid $color-white;
    }
  }
  @if $direction == "right" {
    //오른쪽으로 뾰족
    &:before {
      left:100%;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 14px solid $color-gray-300;
    }
    &:after {
      left:100%;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      border-left: 12px solid $color-white;
    }
  }
  @if $direction == "down" {
    //아랫쪽으로 뾰족
    &:before {
      top:100%;
      border-top: 14px solid $color-gray-300;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.12));
    }
    &:after {
      top:100%;
      border-top: 12px solid $color-white;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
    }
  }
  @if $direction == "left" {
    //왼쪽으로 뾰족
    &:before {
      right:100%;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-right: 14px solid $color-gray-300;
    }
    &:after {
      right:100%;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      border-right: 12px solid $color-white;
    }
  }
}


//꼬리 위치 잡기
@mixin tooltipTailPosition($position) {
  @if $position == "top-left" or $position == "top" or $position == "top-right" {
    margin-top: 14px;
    @include tailDirection('up')
  }
  @if $position == "right-top" or $position == "right" or $position == "right-bottom" {
    margin-right: 14px;
    @include tailDirection('right')
  }
  @if $position == "bottom-left" or $position == "bottom" or $position == "bottom-right" {
    margin-bottom: 14px;
    @include tailDirection('down')
  }
  @if $position == "left-top" or $position == "left" or $position == "left-bottom" {
    margin-left: 14px;
    @include tailDirection('left')
  }


  @if $position == "left-top" or $position == "right-top" {
    &:before {
      top: 16px;
    }
    &:after {
      top: 17px;
    }
  }
  @if $position == "top-right" or $position == "bottom-right"  {
    &:before {
      right: 16px;
    }
    &:after {
       right: 17px;
     }
  }
  @if $position == "left-bottom" or $position == "right-bottom" {
    &:before {
      bottom: 16px;
    }
    &:after {
      bottom: 17px;
    }
  }
  @if $position == "top-left" or $position == "bottom-left" {
    &:before {
      left: 16px;
    }
    &:after {
      left: 17px;
    }
  }

}